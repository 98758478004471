import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import hideLikesIcon from '../../../../../common/assets/icons/hide-likes.png';
import AvatarNetwork from '../../../../../common/components/AvatarNetwork';
import { selectPLFavorite } from '../../../../../common/components/ButtonModalLists/slice';
import { DirectButton } from '../../../../../common/components/DirectButton';
import ModalInfluencerSummary from '../../components/ModalInfluencerSummary';
import { selectInfluencerDetailsSlice, updateFavorite } from '../../slice';

import './index.scss';

const InfluencerHeader = ({ influencerId, isSimple }) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const favoriteList = useSelector(selectPLFavorite);
  const influencerSlice = useSelector(selectInfluencerDetailsSlice);
  const [showSummary, setShowSummary] = useState(false);

  const id = influencerId || params.id;

  const influencer = influencerSlice.influencer;

  useEffect(() => {
    if (favoriteList && favoriteList.influencerIds) {
      dispatch(updateFavorite(favoriteList.influencerIds.includes(id)));
    }
  }, [favoriteList]);

  const aboutData = [
    { icon: 'pin-location-1', value: influencer.bioPlaces },
    { icon: 'phone', value: influencer.phones },
    { icon: 'email', value: influencer.emails },
  ];

  return (
    <>
      <ModalInfluencerSummary
        isVisible={showSummary}
        influencer={influencer}
        onClose={() => setShowSummary(false)}
      />

      <div className="influencer-profile">
        <AvatarNetwork
          size={227}
          src={influencer.avatar}
          network={influencer.network}
          onClick={() => window.open(influencer.profileUrl)}
        />

        <div className="influencer-data">
          <div className="name-verified-classification">
            <div className="name">
              {influencer.name}

              {influencer.verified && <i className="icon icon-certificate" />}
            </div>

            {!isSimple && influencer.classification && (
              <Tooltip
                overlayClassName="tooltip-influencers-classification"
                title={
                  <div className="classification_tooltip">
                    <p className="">{t('Classificação dos influenciadores')}</p>

                    <div className="junction">
                      Mega <span></span> <b>1M+</b>
                    </div>
                    <div className="junction">
                      Macro <span></span> <b>500K - 1M</b>
                    </div>
                    <div className="junction">
                      Inter <span></span> <b>50K - 500K</b>
                    </div>
                    <div className="junction">
                      Micro <span></span> <b>10K - 50K</b>
                    </div>
                    <div className="junction">
                      Nano <span></span> <b>1K - 10K</b>
                    </div>
                  </div>
                }
              >
                <div className="classification">{influencer.classification}</div>
              </Tooltip>
            )}
          </div>

          {!isSimple && (
            <div style={{ marginTop: 6 }}>
              <p className="username hover" onClick={() => setShowSummary(true)}>
                @{influencer.username}
              </p>
              {(influencer.network === 'instagram' || influencer.network === 'tiktok') && (
                <DirectButton influencer={influencer} />
              )}
            </div>
          )}

          <p className="bio">
            <b>{t('Bio')}</b>

            <br />

            {influencer.bioSlice || t('Sem bio')}
          </p>

          {aboutData.some((about) => about.value !== null && about.value !== '') && (
            <div className="about">
              <b className="new-feature">{t('Sobre')}</b>

              <div className="about-data-junction">
                {aboutData.map(({ value, icon }, i) => {
                  if (value && typeof value === 'string') {
                    return (
                      <div className="about-data" key={`about-${i}`}>
                        <i className={`icon icon-${icon}`} />

                        <span>{value} </span>
                      </div>
                    );
                  } else if (value && value.length > 0) {
                    return (
                      <div className="about-data" key={`about-${i}`}>
                        <i className={`icon icon-${icon}`} />

                        {value &&
                          value.map((v, i) => {
                            return (
                              <span key={`values-${i}`}>
                                {i !== 0 &&
                                  value.length > 1 &&
                                  (i === value.length - 1 ? <span> e </span> : ', ')}

                                {v}
                              </span>
                            );
                          })}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

          {(influencer.acceleratedGrowth || influencer.hiddenLikes) && (
            <div className="part-stamps">
              <p style={{ marginBottom: 0 }}>
                <b>{t('Selos')}</b>
              </p>

              <div className="stamps">
                {influencer.acceleratedGrowth && (
                  <div className="accelerated-growth">
                    <i className="icon icon-rocket" />

                    <span>{t('Crescimento acelerado')}</span>
                  </div>
                )}

                {influencer.hiddenLikes && (
                  <div className="profile-hide-like">
                    <img
                      src={hideLikesIcon}
                      alt={t('mão fechada com o polegar levantado e um olho com um risco')}
                    />

                    {t('Oculta curtidas com frequência')}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

InfluencerHeader.defaultProps = {
  isSimple: false,
};

InfluencerHeader.propTypes = {
  isSimple: PropTypes.bool,
  influencerId: PropTypes.string,
};

export default InfluencerHeader;
